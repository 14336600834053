@import "./ini.css";
@import url('https://fonts.googleapis.com/css?family=PT+Sans&display=swap');
body{
  min-height: 100vh;
  margin: 0;
  font-family: 'PT Sans';
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.container {
  margin: 0 auto;
  position: relative;
  width: auto;

}
@media (max-width: 1022px) {
  .container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
@media (min-width: 1023px) and (max-width: 1215px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1216px) and (max-width: 1440px) {
  .container {
    max-width: 1152px;
  }
}
@media (min-width: 1441px) {
  .container {
    max-width: 1344px;
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
img{
  max-width: 100%;
}