@import url(https://fonts.googleapis.com/css?family=PT+Sans&display=swap);
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  color: #000;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.94rem; }

a {
  text-decoration: none;
  color: #575757; }

img {
  max-width: 100%; }

*,
*::before,
*::after {
  box-sizing: border-box; }

.overflow-hidden {
  overflow: hidden; }

button[disabled],
input[disabled],
textarea[disabled],
select[disabled],
button:active[disabled],
input:active[disabled],
textarea:active[disabled],
select:active[disabled] {
  cursor: not-allowed;
  background: #f7f7f7 !important;
  opacity: 0.7;
  border-color: #f7f7f7 !important; }

.hidden {
  display: none; }

.ini.overflow.hidden {
  overflow: hidden; }

/*
===============================================================
    INI TEXT
===============================================================
*/
.ini.align.left {
  text-align: left; }

.ini.align.right {
  text-align: right; }

.ini.align.center {
  text-align: center; }

.ini.align.justify {
  text-align: justify; }

.ini.lowercase {
  text-transform: lowercase !important; }

.ini.uppercase {
  text-transform: uppercase !important; }

.ini.capitalize {
  text-transform: capitalize !important; }

.ini.bold {
  font-weight: bold; }

.ini.light {
  font-weight: 300; }

.ini.italic {
  font-style: italic !important; }

/*
===============================================================
    INI POSITION
===============================================================
*/
.ini.float-left {
  float: left; }

.ini.float-right {
  float: right; }

.ini.float-none {
  float: none; }

.ini.margin.left {
  margin-left: auto; }

.ini.margin.right {
  margin-right: auto; }

.ini.margin.center {
  margin: 0 auto; }

.ini.center-all {
  display: flex;
  justify-content: center;
  align-items: center; }

/*
===============================================================
    INI GRID
===============================================================
*/
.ini.grids {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px; }

.grid {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  padding-right: 10px;
  padding-left: 10px; }

.grid-12 {
  flex: none;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px; }

.grid-11 {
  flex: none;
  width: 91.666667%;
  padding-right: 10px;
  padding-left: 10px; }

.grid-10 {
  flex: none;
  width: 83.333333%;
  padding-right: 10px;
  padding-left: 10px; }

.grid-9 {
  flex: none;
  width: 75%;
  padding-right: 10px;
  padding-left: 10px; }

.grid-8 {
  flex: none;
  width: 66.666667%;
  padding-right: 10px;
  padding-left: 10px; }

.grid-7 {
  flex: none;
  width: 58.333333%;
  padding-right: 10px;
  padding-left: 10px; }

.grid-6 {
  flex: none;
  width: 50%;
  padding-right: 10px;
  padding-left: 10px; }

.grid-5 {
  flex: none;
  width: 41.666667%;
  padding-right: 10px;
  padding-left: 10px; }

.grid-4 {
  flex: none;
  width: 33.333333%;
  padding-right: 10px;
  padding-left: 10px; }

.grid-3 {
  flex: none;
  width: 25%;
  padding-right: 10px;
  padding-left: 10px; }

.grid-2 {
  flex: none;
  width: 16.666667%;
  padding-right: 10px;
  padding-left: 10px; }

.grid-1 {
  flex: none;
  width: 8.333333%;
  padding-right: 10px;
  padding-left: 10px; }

.grid.xsmall-1,
.grid.xsmall-2,
.grid.xsmall-3,
.grid.xsmall-4,
.grid.xsmall-5,
.grid.xsmall-6,
.grid.xsmall-7,
.grid.xsmall-8,
.grid.xsmall-9,
.grid.xsmall-10,
.grid.xsmall-11,
.grid.xsmall-12,
.grid.small-1,
.grid.small-2,
.grid.small-3,
.grid.small-4,
.grid.small-5,
.grid.small-6,
.grid.small-7,
.grid.small-8,
.grid.small-9,
.grid.small-10,
.grid.small-11,
.grid.small-12,
.grid.medium-1,
.grid.medium-2,
.grid.medium-3,
.grid.medium-4,
.grid.medium-5,
.grid.medium-6,
.grid.medium-7,
.grid.medium-8,
.grid.medium-9,
.grid.medium-10,
.grid.medium-11,
.grid.medium-12,
.grid.large-1,
.grid.large-2,
.grid.large-3,
.grid.large-4,
.grid.large-5,
.grid.large-6,
.grid.large-7,
.grid.large-8,
.grid.large-9,
.grid.large-10,
.grid.large-11,
.grid.large-12,
.grid.xlarge-1,
.grid.xlarge-2,
.grid.xlarge-3,
.grid.xlarge-4,
.grid.xlarge-5,
.grid.xlarge-6,
.grid.xlarge-7,
.grid.xlarge-8,
.grid.xlarge-9,
.grid.xlarge-10,
.grid.xlarge-11,
.grid.xlarge-12 {
  width: 100%;
  flex-basis: auto;
  flex-grow: inherit; }

@media (min-width: 0px) {
  .grid.xsmall-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
    padding-right: 10px;
    padding-left: 10px; }

  .grid.xsmall-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
    padding-right: 10px;
    padding-left: 10px; }

  .grid.xsmall-3 {
    flex: 0 0 25%;
    max-width: 25%;
    padding-right: 10px;
    padding-left: 10px; }

  .grid.xsmall-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    padding-right: 10px;
    padding-left: 10px; }

  .grid.xsmall-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
    padding-right: 10px;
    padding-left: 10px; }

  .grid.xsmall-6 {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 10px;
    padding-left: 10px; }

  .grid.xsmall-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
    padding-right: 10px;
    padding-left: 10px; }

  .grid.xsmall-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
    padding-right: 10px;
    padding-left: 10px; }

  .grid.xsmall-9 {
    flex: 0 0 75%;
    max-width: 75%;
    padding-right: 10px;
    padding-left: 10px; }

  .grid.xsmall-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
    padding-right: 10px;
    padding-left: 10px; }

  .grid.xsmall-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
    padding-right: 10px;
    padding-left: 10px; }

  .grid.xsmall-12 {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 10px;
    padding-left: 10px; } }
@media (min-width: 576px) {
  .grid.small-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
    padding-right: 10px;
    padding-left: 10px; }

  .grid.small-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
    padding-right: 10px;
    padding-left: 10px; }

  .grid.small-3 {
    flex: 0 0 25%;
    max-width: 25%;
    padding-right: 10px;
    padding-left: 10px; }

  .grid.small-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    padding-right: 10px;
    padding-left: 10px; }

  .grid.small-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
    padding-right: 10px;
    padding-left: 10px; }

  .grid.small-6 {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 10px;
    padding-left: 10px; }

  .grid.small-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
    padding-right: 10px;
    padding-left: 10px; }

  .grid.small-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
    padding-right: 10px;
    padding-left: 10px; }

  .grid.small-9 {
    flex: 0 0 75%;
    max-width: 75%;
    padding-right: 10px;
    padding-left: 10px; }

  .grid.small-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
    padding-right: 10px;
    padding-left: 10px; }

  .grid.small-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
    padding-right: 10px;
    padding-left: 10px; }

  .grid.small-12 {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 10px;
    padding-left: 10px; } }
@media (min-width: 768px) {
  .grid.medium-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
    padding-right: 10px;
    padding-left: 10px; }

  .grid.medium-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
    padding-right: 10px;
    padding-left: 10px; }

  .grid.medium-3 {
    flex: 0 0 25%;
    max-width: 25%;
    padding-right: 10px;
    padding-left: 10px; }

  .grid.medium-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    padding-right: 10px;
    padding-left: 10px; }

  .grid.medium-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
    padding-right: 10px;
    padding-left: 10px; }

  .grid.medium-6 {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 10px;
    padding-left: 10px; }

  .grid.medium-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
    padding-right: 10px;
    padding-left: 10px; }

  .grid.medium-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
    padding-right: 10px;
    padding-left: 10px; }

  .grid.medium-9 {
    flex: 0 0 75%;
    max-width: 75%;
    padding-right: 10px;
    padding-left: 10px; }

  .grid.medium-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
    padding-right: 10px;
    padding-left: 10px; }

  .grid.medium-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
    padding-right: 10px;
    padding-left: 10px; }

  .grid.medium-12 {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 10px;
    padding-left: 10px; } }
@media (min-width: 992px) {
  .grid.large-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
    padding-right: 10px;
    padding-left: 10px; }

  .grid.large-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
    padding-right: 10px;
    padding-left: 10px; }

  .grid.large-3 {
    flex: 0 0 25%;
    max-width: 25%;
    padding-right: 10px;
    padding-left: 10px; }

  .grid.large-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    padding-right: 10px;
    padding-left: 10px; }

  .grid.large-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
    padding-right: 10px;
    padding-left: 10px; }

  .grid.large-6 {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 10px;
    padding-left: 10px; }

  .grid.large-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
    padding-right: 10px;
    padding-left: 10px; }

  .grid.large-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
    padding-right: 10px;
    padding-left: 10px; }

  .grid.large-9 {
    flex: 0 0 75%;
    max-width: 75%;
    padding-right: 10px;
    padding-left: 10px; }

  .grid.large-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
    padding-right: 10px;
    padding-left: 10px; }

  .grid.large-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
    padding-right: 10px;
    padding-left: 10px; }

  .grid.large-12 {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 10px;
    padding-left: 10px; } }
@media (min-width: 1200px) {
  .grid.xlarge-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
    padding-right: 10px;
    padding-left: 10px; }

  .grid.xlarge-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
    padding-right: 10px;
    padding-left: 10px; }

  .grid.xlarge-3 {
    flex: 0 0 25%;
    max-width: 25%;
    padding-right: 10px;
    padding-left: 10px; }

  .grid.xlarge-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    padding-right: 10px;
    padding-left: 10px; }

  .grid.xlarge-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
    padding-right: 10px;
    padding-left: 10px; }

  .grid.xlarge-6 {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 10px;
    padding-left: 10px; }

  .grid.xlarge-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
    padding-right: 10px;
    padding-left: 10px; }

  .grid.xlarge-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
    padding-right: 10px;
    padding-left: 10px; }

  .grid.xlarge-9 {
    flex: 0 0 75%;
    max-width: 75%;
    padding-right: 10px;
    padding-left: 10px; }

  .grid.xlarge-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
    padding-right: 10px;
    padding-left: 10px; }

  .grid.xlarge-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
    padding-right: 10px;
    padding-left: 10px; }

  .grid.xlarge-12 {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 10px;
    padding-left: 10px; } }
/*
===============================================================
    INI CONTAINER
===============================================================
*/
.ini.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%; }

@media (min-width: 576px) {
  .ini.container {
    max-width: 700px; } }
@media (min-width: 768px) {
  .ini.container {
    max-width: 900px; } }
@media (min-width: 992px) {
  .ini.container {
    max-width: 960px; } }
@media (min-width: 1200px) {
  .ini.container {
    max-width: 1140px; } }
@media (min-width: 1366px) {
  .ini.container {
    max-width: 1206px; } }
/*
===============================================================
    INI DISPLAY & POSITION
===============================================================
*/
.ini.display.block {
  display: block; }

.ini.display.inline-block {
  display: inline-block; }

.ini.display.flex {
  display: flex; }

.ini.display.inline-flex {
  display: inline-flex; }

.ini.display.inline {
  display: inline; }

.ini.display.none {
  display: none; }

.ini.relative {
  position: relative; }

.ini.absolute {
  position: absolute; }

.ini.fixed {
  position: fixed; }

.ini.sticky {
  position: -webkit-sticky;
  position: sticky; }

/*
===============================================================
    INI BACKGROUND
===============================================================
*/
.ini.background-transparent {
  background: transparent; }

.ini.background-blue {
  background: #2980b9; }

.ini.background-red {
  background: #f82d51; }

.ini.background-inicss {
  background: #fc732b; }

.ini.background-gray {
  background: #7f8c8d; }

.ini.background-black {
  background: #000; }

.ini.background-orange {
  background: #f39c12; }

.ini.background-carrot {
  background: #e67e22; }

.ini.background-purple {
  background: #9b59b6; }

.ini.background-orange {
  background: #f39c12; }

.ini.background-midnight {
  background: #2c3e50; }

.ini.background-primary {
  background: #0fb9b1; }

.ini.background-green {
  background: #2ecc71; }

/*
===============================================================
    INI COLOR
===============================================================
*/
.ini.color-white {
  color: white; }

.ini.color-blue {
  color: #2980b9; }

.ini.color-red {
  color: #f82d51; }

.ini.color-gray {
  color: #7f8c8d; }

.ini.color-black {
  color: #000; }

.ini.color-orange {
  color: #f39c12; }

.ini.color-carrot {
  color: #e67e22; }

.ini.color-purple {
  color: #9b59b6; }

.ini.color-orange {
  color: #f39c12; }

.ini.color-midnight {
  color: #2c3e50; }

.ini.color-primary {
  color: #0fb9b1; }

.ini.color-green {
  color: #2ecc71; }

/*
===============================================================
    INI MODAL
===============================================================
*/
.closeModal,
.openModal {
  cursor: pointer; }

.ini.modals {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.9);
  height: 100%;
  width: 100%;
  z-index: 998;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  padding: 1rem;
  overflow: auto; }
  .ini.modals .ini.modal {
    -webkit-transform: scaleX(0.8) scaleY(0.8);
            transform: scaleX(0.8) scaleY(0.8);
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    margin: 2rem auto;
    background: white;
    width: inherit; }
    .ini.modals .ini.modal .header {
      display: flex;
      padding: 1rem 1.5rem 0 1.5rem; }
      .ini.modals .ini.modal .header .title {
        font-size: 1.42857143rem;
        line-height: 1.28571429em;
        font-weight: 700; }
    .ini.modals .ini.modal .content {
      padding: 1.5rem; }
    .ini.modals .ini.modal .action {
      padding: 0.5rem 1.5rem;
      border-top: 1px solid rgba(34, 36, 38, 0.15);
      text-align: right; }
      .ini.modals .ini.modal .action button {
        margin: 0 0.3rem; }
      .ini.modals .ini.modal .action button:last-child {
        margin-right: 0; }
    .ini.modals .ini.modal .button.close {
      margin-left: auto;
      padding: 0;
      color: #a0a0a0; }
      .ini.modals .ini.modal .button.close i {
        font-size: 22px; }
  .ini.modals .ini.modal.small {
    max-width: 540px; }
  .ini.modals .ini.modal.medium {
    max-width: 720px; }
  .ini.modals .ini.modal.large {
    max-width: 1080px; }

.ini.modals.active {
  visibility: visible;
  opacity: 1;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }
  .ini.modals.active .ini.modal {
    -webkit-transform: scaleX(1) scaleY(1);
            transform: scaleX(1) scaleY(1);
    -webkit-transition: all 0.2s;
    transition: all 0.2s; }

.ini.modals.centered {
  display: flex;
  justify-content: center;
  align-items: center; }

@media (max-width: 575px) {
  .ini.modal {
    min-width: 100%;
    max-width: 100%; } }
/*
===============================================================
    INI NAVBAR
===============================================================
*/
.ini.navbar {
  display: flex;
  padding: 0.5rem 1rem;
  box-shadow: 0 2px 0 0 #f5f5f5;
  margin-bottom: 1rem;
  background: #f1f1f1; }
  .ini.navbar img {
    vertical-align: top !important;
    height: 30px; }
  .ini.navbar .ini.menus {
    display: inline-flex;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
  .ini.navbar .navbar.brand {
    padding: 0px 10px;
    font-size: 20px;
    line-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center; }
  .ini.navbar .menu.left,
  .ini.navbar .menu.right,
  .ini.navbar .menu.center {
    flex: 1 1;
    line-height: 30px; }
    .ini.navbar .menu.left a,
    .ini.navbar .menu.right a,
    .ini.navbar .menu.center a {
      padding: 0.5rem 1rem; }
  .ini.navbar .menu.left {
    text-align: left; }
  .ini.navbar .menu.center {
    text-align: center; }
  .ini.navbar .menu.right {
    text-align: right; }
  .ini.navbar .ini.dropdown {
    display: inline-block; }
    .ini.navbar .ini.dropdown .menu {
      overflow: visible;
      overflow: initial;
      position: absolute;
      top: 5.1rem; }
    .ini.navbar .ini.dropdown .menu::before {
      content: "";
      position: absolute;
      top: -0.5rem;
      left: 0.8rem;
      z-index: 1;
      border-width: 1px 0px 0px 1px;
      width: 0;
      height: 0;
      border-left: 0.5rem solid transparent;
      border-right: 0.5rem solid transparent;
      border-bottom: 0.5rem solid white; }

.ini.navbar.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 990; }

.ini.navbar.fixed {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 990;
  margin-bottom: 0 !important; }
  .ini.navbar.fixed .ini.dropdown .menu {
    top: 3.2rem; }

@media (min-width: 768px) {
  .ini.navbar.responsive .ini.hamburger {
    display: none; } }
@media (max-width: 767px) {
  .ini.navbar.responsive {
    display: block; }
    .ini.navbar.responsive .navbar.brand {
      display: inline-block; }
    .ini.navbar.responsive .ini.menus {
      display: none !important;
      box-shadow: 0 8px 16px rgba(10, 10, 10, 0.1);
      padding: 12px; }
    .ini.navbar.responsive .ini.menus.active {
      display: block !important; }
    .ini.navbar.responsive .ini.menus.side {
      height: 100vh;
      position: fixed;
      background: white;
      top: 0;
      left: -80%;
      z-index: 996;
      display: block !important;
      width: 80%;
      -webkit-transition: left ease-in 0.5s;
      transition: left ease-in 0.5s; }
    .ini.navbar.responsive .ini.menus.side.active {
      left: 0%;
      -webkit-transition: left ease-out 0.5s;
      transition: left ease-out 0.5s; } }
  @media (max-width: 767px) and (max-width: 575px) {
    .ini.navbar.responsive .ini.menus.side {
      width: 250px; } }
@media (max-width: 767px) {
    .ini.navbar.responsive .ini.hamburger {
      display: inline-block;
      float: right; }
    .ini.navbar.responsive .menu {
      text-align: left !important;
      line-height: normal !important; }
      .ini.navbar.responsive .menu a {
        padding: 0.5rem 0.6rem 0.5rem 0rem !important;
        display: block; } }
/*
===============================================================
    INI HAMBURGER
===============================================================
*/
.ini.hamburger span {
  height: 2px;
  width: 25px;
  background: #929292;
  display: -webkit-box;
  position: relative;
  left: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.ini.hamburger span:first-child {
  -webkit-transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1); }

.ini.hamburger span:nth-child(2),
.ini.hamburger span:last-child {
  margin-top: 6px;
  -webkit-transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1); }

.ini.hamburger.active {
  position: relative;
  top: 8px; }

.ini.hamburger.active span:first-child {
  -webkit-transform: translate3d(0, 0, 0) rotate(45deg);
          transform: translate3d(0, 0, 0) rotate(45deg);
  -webkit-transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1); }

.ini.hamburger.active span:nth-child(2) {
  opacity: 0; }

.ini.hamburger.active span:last-child {
  -webkit-transform: translate3d(0, -16px, 0) rotate(-45deg);
          transform: translate3d(0, -16px, 0) rotate(-45deg);
  -webkit-transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1); }

.ini.hamburger:hover:not(.active) span:first-child {
  left: 10px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.ini.hamburger:hover:not(.active) span:nth-child(2) {
  left: 5px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

/*
===============================================================
    INI ACCORDION
===============================================================
*/
.ini.menu.vertical {
  list-style: none;
  padding-left: 0;
  margin: 0; }
  .ini.menu.vertical > li > a {
    display: block;
    padding: 10px;
    cursor: pointer;
    font-weight: 600; }
  .ini.menu.vertical .openCollapse::after {
    font-family: "Material Icons";
    content: "expand_more";
    float: right; }
  .ini.menu.vertical .openCollapse.active::after {
    content: "expand_less"; }
  .ini.menu.vertical .collapse.active {
    max-height: 999px;
    -webkit-transition: max-height 0.5s;
    transition: max-height 0.5s; }
  .ini.menu.vertical .collapse {
    list-style: none;
    overflow: hidden;
    padding-left: 20px;
    max-height: 0px;
    -webkit-transition: max-height 0.3s;
    transition: max-height 0.3s; }
    .ini.menu.vertical .collapse li {
      display: block;
      padding: 7px;
      cursor: pointer;
      color: #797979;
      font-size: 14px;
      width: calc(100% - 23px); }
    .ini.menu.vertical .collapse li.active a,
    .ini.menu.vertical .collapse li:hover,
    .ini.menu.vertical .collapse a:hover {
      color: #fc732b; }

/*
===============================================================
    INI BUTTON
===============================================================
*/
.ini.button {
  color: white;
  padding: 6px 15px;
  outline: none;
  box-shadow: none;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  background: transparent;
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.ini.button.blue {
  background: #2980b9;
  border: 1px solid #2980b9; }

.ini.button.blue:hover {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: #276994; }

.ini.button.o-blue {
  border: 1px solid #2980b9;
  color: #2980b9; }

.ini.button.o-blue:hover {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: #2980b9;
  color: white; }

.ini.button.primary {
  background: #0fb9b1;
  border: 1px solid #0fb9b1; }

.ini.button.primary:hover {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: #05918b; }

.ini.button.o-primary {
  border: 1px solid #0fb9b1;
  color: #0fb9b1; }

.ini.button.o-primary:hover {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: #0fb9b1;
  color: white; }

.ini.button.inicss {
  border: 1px solid #fc732b;
  background: #fc732b; }

.ini.button.inicss:hover {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: #e86520; }

.ini.button.o-inicss {
  border: 1px solid #fc732b;
  color: #fc732b; }

.ini.button.o-inicss:hover {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: #fc732b;
  color: white; }

.ini.button.red {
  border: 1px solid #f82d51;
  background: #f82d51; }

.ini.button.red:hover {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: #d72e4c; }

.ini.button.o-red {
  border: 1px solid #f82d51;
  color: #f82d51; }

.ini.button.o-red:hover {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: #f82d51;
  color: white; }

.ini.button.gray {
  border: 1px solid #7f8c8d;
  background: #7f8c8d; }

.ini.button.gray:hover {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: #6b6b6b; }

.ini.button.o-gray {
  border: 1px solid #7f8c8d;
  color: #7f8c8d; }

.ini.button.o-gray:hover {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: #7f8c8d;
  color: white; }

.ini.button.black {
  border: 1px solid #000;
  background: #000; }

.ini.button.black:hover {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: #505050; }

.ini.button.o-black {
  border: 1px solid #000;
  color: #000; }

.ini.button.o-black:hover {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: #000;
  color: white; }

.ini.button.orange {
  border: 1px solid #f39c12;
  background: #f39c12; }

.ini.button.orange:hover {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: #e29214; }

.ini.button.o-orange {
  border: 1px solid #f39c12;
  color: #f39c12; }

.ini.button.o-orange:hover {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: #f39c12;
  color: white; }

.ini.button.carrot {
  border: 1px solid #e67e22;
  background: #e67e22; }

.ini.button.carrot:hover {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: #da6c0b; }

.ini.button.o-carrot {
  border: 1px solid #e67e22;
  color: #e67e22; }

.ini.button.o-carrot:hover {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: #e67e22;
  color: white; }

.ini.button.purple {
  border: 1px solid #9b59b6;
  background: #9b59b6; }

.ini.button.purple:hover {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: #8135a0; }

.ini.button.o-purple {
  border: 1px solid #9b59b6;
  color: #9b59b6; }

.ini.button.o-purple:hover {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: #9b59b6;
  color: white; }

.ini.button.midnight {
  border: 1px solid #2c3e50;
  background: #2c3e50; }

.ini.button.midnight:hover {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: #516982; }

.ini.button.o-midnight {
  border: 1px solid #2c3e50;
  color: #2c3e50; }

.ini.button.o-midnight:hover {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: #2c3e50;
  color: white; }

.ini.button.green {
  border: 1px solid #2ecc71;
  background: #2ecc71; }

.ini.button.green:hover {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: #18b159; }

.ini.button.o-green {
  border: 1px solid #2ecc71;
  color: #2ecc71; }

.ini.button.o-green:hover {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: #2ecc71;
  color: white; }

.ini.button.o-white {
  border: 1px solid white;
  color: white; }

.ini.button.loader {
  display: unset;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: auto;
  color: transparent !important;
  cursor: default;
  pointer-events: none; }

.ini.button.loader:after,
.ini.button.loader:before {
  width: 20px;
  height: 20px; }

.ini.button.loader:before {
  border: 3px solid #fff; }

.ini.button.loader.inverse:before {
  border: 3px solid #afafaf !important; }

.ini.button.loader:after {
  border-top: 3px solid #fff; }

.ini.button.loader.inverse:after {
  border-top: 3px solid #ccc !important; }

/*
===============================================================
    display
===============================================================
*/
@media (max-width: 575px) {
  .ini.hide.down-small {
    display: none; } }
@media (min-width: 576px) {
  .ini.hide.down-small {
    display: show; } }
/*
===============================================================
    INI TABLE
===============================================================
*/
.ini.table {
  width: 100%;
  background: #fff;
  text-align: left;
  border-spacing: 0;
  font-size: 14px; }
  .ini.table thead tr th,
  .ini.table tbody tr td {
    padding: 0.78571429em 0.78571429em; }
  .ini.table thead {
    border-spacing: 0;
    background: #e4e4e4; }
  .ini.table tbody tr td {
    border-bottom: 1px solid #ececec; }

.ini.table.striped tbody tr:nth-of-type(odd) {
  background: #f7f7f7; }

.ini.table.bordered {
  border: 1px solid #eaeaea;
  border-radius: 3px; }
  .ini.table.bordered thead tr th {
    border-right: 1px solid #fff; }
  .ini.table.bordered tbody tr td {
    border: 0px solid #eaeaea;
    border-width: 0px 1px 1px 0px; }
  .ini.table.bordered tbody tr td:last-child,
  .ini.table.bordered thead tr th:last-child {
    border-right: none; }
  .ini.table.bordered tbody tr:last-child td {
    border-bottom: none; }

/*
===============================================================
    INI PAGINATION
===============================================================
*/
.ini.pagination {
  text-align: right;
  font-size: 14px;
  margin-top: 0.5rem; }
  .ini.pagination ul {
    list-style: none;
    padding: 0; }
    .ini.pagination ul li {
      display: inline-block;
      border: 1px solid #e2e2e2;
      border-right: none;
      min-width: 2.3rem;
      text-align: center;
      padding: 0.4rem;
      -webkit-transition: all 0.3s;
      transition: all 0.3s; }
    .ini.pagination ul li:first-child {
      border-radius: 5px 0px 0px 5px; }
    .ini.pagination ul li:last-child {
      border-radius: 0px 5px 5px 0px;
      border-right: 1px solid #e2e2e2; }
    .ini.pagination ul .active {
      background: #cecece; }
    .ini.pagination ul li:hover {
      background: #cecece;
      -webkit-transition: all 0.3s;
      transition: all 0.3s; }

/*
===============================================================
    INI IMAGE
===============================================================
*/
img.ini.rounded {
  border-radius: 5% !important; }

img.ini.circle {
  border-radius: 100% !important; }

img.ini.thumbnail {
  padding: 0.5rem;
  border: 1px solid #f5f5f5; }

/*
===============================================================
    INI CARD
===============================================================
*/
.ini.card .card.content {
  padding: 0.5rem;
  border: 1px solid #f5f5f5;
  display: flow-root; }
.ini.card .card.title {
  font-size: 1.5rem;
  font-weight: bold; }
.ini.card .card.subtitle {
  font-size: 14px;
  color: #cccccc; }
.ini.card .card.text {
  margin-top: 0.5rem;
  font-size: 14px; }
.ini.card .card.footer {
  padding: 1rem 0.5rem;
  border: 1px solid #f5f5f5;
  display: flow-root;
  font-size: 14px; }

/*
===============================================================
    INI FORM
===============================================================
*/
.ini.form {
  margin-bottom: 0.6rem; }
  .ini.form > label {
    display: block;
    color: black;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 0.3rem; }
  .ini.form .ini.input,
  .ini.form .ini.textarea,
  .ini.form .select {
    width: 100% !important; }
  .ini.form .icon.right,
  .ini.form .icon.left {
    position: relative !important; }
    .ini.form .icon.right i,
    .ini.form .icon.left i {
      position: absolute;
      line-height: 1;
      top: 0;
      padding: 7px;
      margin: 0;
      opacity: 0.6;
      font-size: 1.4rem; }
  .ini.form .icon.right .ini.input {
    padding-right: 2rem !important; }
  .ini.form .icon.right i {
    right: 0; }
  .ini.form .icon.left .ini.input {
    padding-left: 2rem !important; }
  .ini.form .icon.left i {
    left: 0; }
  .ini.form > .checkbox,
  .ini.form > .radio,
  .ini.form > .toggle,
  .ini.form > .slider {
    display: inline-flex;
    align-items: center;
    margin-right: 1rem; }
    .ini.form > .checkbox label,
    .ini.form > .radio label,
    .ini.form > .toggle label,
    .ini.form > .slider label {
      font-size: 14px;
      margin: 0px 0px 0px 5px !important;
      cursor: pointer; }
  .ini.form > .checkbox:last-child,
  .ini.form > .radio:last-child {
    margin-right: 0; }

.ini.form:last-child {
  margin-bottom: 0; }

.ini.textarea {
  height: auto !important; }

.ini.input,
.ini.textarea,
.ini.select {
  max-width: 100% !important;
  background: white !important;
  padding: 0.6rem !important;
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  border-radius: 0.28571429rem !important;
  height: 36px; }

.ini.input::-webkit-input-placeholder {
  opacity: 0.5; }

.ini.input:-ms-input-placeholder {
  opacity: 0.5; }

.ini.input::-ms-input-placeholder {
  opacity: 0.5; }

.ini.input::placeholder {
  opacity: 0.5; }

.ini.input:active,
.ini.input:focus,
.ini.textarea:active,
.ini.textarea:focus,
.ini.select:active,
.ini.select:focus {
  outline: none !important;
  border: 1px solid rgba(0, 65, 111, 0.48) !important; }

.ini.input.rouneded {
  border-radius: 2rem !important; }

.ini.checkbox,
.ini.radio,
.ini.toggle,
.ini.slider {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: lightgray;
  height: 20px;
  width: 20px;
  display: inline-block;
  margin: 0; }

.ini.radio,
.ini.toggle,
.ini.slider {
  border-radius: 50%; }

.ini.checkbox:focus,
.ini.radio:focus,
.ini.toggle:focus,
.ini.slider:focus {
  outline: none !important; }

.ini.checkbox:checked,
.ini.radio:checked,
.ini.toggle:checked,
.ini.slider:checked {
  background: #fc732b;
  -webkit-transition: all 0.7s;
  transition: all 0.7s; }

.ini.checkbox:after,
.ini.radio:after {
  content: "";
  position: relative;
  top: 23%;
  left: 43%;
  width: 18%;
  height: 44%;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  display: none; }

.ini.checkbox:checked:after,
.ini.radio:checked:after,
.ini.toggle:checked:after,
.ini.toggle:checked:after {
  display: block; }

.ini.toggle {
  width: 50px !important;
  border-radius: 2rem !important;
  position: relative; }

.ini.toggle:after,
.ini.slider:after {
  content: "";
  position: absolute;
  display: block;
  box-shadow: rgba(34, 36, 38, 0.15) 0px 1px 2px 0px, rgba(34, 36, 38, 0.15) 0px 0px 0px 1px inset;
  width: 1.26rem;
  height: 1.26rem;
  background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(0, 0, 0, 0.05))) white;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.05)) white;
  border-radius: 50%;
  -webkit-transition: all 0.7s;
  transition: all 0.7s;
  left: 0%;
  -webkit-transform: translate(0); }

.ini.toggle:checked:after,
.ini.slider:checked:after {
  left: 100%;
  -webkit-transform: translate(-100%);
  -webkit-transition: all 0.7s;
  transition: all 0.7s; }

.ini.slider {
  width: 50px !important;
  border-radius: 0 !important;
  position: relative;
  background: transparent !important; }

.ini.slider::before {
  content: "";
  height: 4px;
  background: #fc732b;
  width: 50px;
  position: absolute;
  top: calc(50% - 2px);
  opacity: 0.3;
  -webkit-transition: all 0.7s;
  transition: all 0.7s;
  right: 0; }

.ini.slider:checked:before {
  opacity: 1;
  -webkit-transition: all 0.7s;
  transition: all 0.7s; }

/*
===============================================================
    INI UPLOAD FILE
===============================================================
*/
.ini.upload.file {
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content; }
  .ini.upload.file .view.image {
    height: 150px;
    width: 150px;
    object-fit: cover; }
  .ini.upload.file .ini.button.upload {
    width: 100%;
    display: block; }

.ini.upload.file.hover {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center; }
  .ini.upload.file.hover .ini.button.upload {
    position: absolute;
    opacity: 0;
    width: 80%; }

.ini.upload.file.hover:hover .view.image {
  -webkit-filter: brightness(60%);
          filter: brightness(60%); }
.ini.upload.file.hover:hover .ini.button.upload {
  opacity: 1;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.ini.upload.file.multiple {
  display: inline-block !important; }
  .ini.upload.file.multiple .ini.button.multiple {
    background: #e8e8e8;
    height: 100px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #bbbbbb;
    font-size: 4rem;
    padding-bottom: 1rem;
    border-radius: 0; }
  .ini.upload.file.multiple .view.many.images {
    display: inline-flex;
    float: left;
    margin: 0 0.5rem 0.5rem 0;
    justify-content: center;
    align-items: center; }
    .ini.upload.file.multiple .view.many.images img {
      height: 100px;
      width: 100px;
      object-fit: cover;
      -webkit-transition: all 0.3s;
      transition: all 0.3s; }
  .ini.upload.file.multiple .view.many.images:last-child {
    margin: 0 0 0.5rem 0; }
  .ini.upload.file.multiple .deleteImage {
    position: absolute;
    color: white;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    opacity: 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s; }
  .ini.upload.file.multiple .view.many.images:hover img {
    -webkit-filter: brightness(40%);
            filter: brightness(40%);
    -webkit-transition: all 0.3s;
    transition: all 0.3s; }
  .ini.upload.file.multiple .view.many.images:hover .deleteImage {
    opacity: 1;
    -webkit-transition: all 0.3s;
    transition: all 0.3s; }

/*
===============================================================
    INI BORDER
===============================================================
*/
.ini.rounded {
  border-radius: 1rem; }

.ini.circle {
  border-radius: 50%; }

.ini.button.upload {
  background: #fc732b;
  padding: 5px 10px;
  color: white; }

.ini.button.upload.hover {
  opacity: 0.5; }

.ini.button.upload:hover {
  background: #1d9e9e;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

/*
===============================================================
    INI Width
===============================================================
*/
.ini.width.fit-content {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content; }

/*
===============================================================
    INI DROPDOWN
===============================================================
*/
.ini.dropdown {
  display: inline-block; }
  .ini.dropdown > .ini.button {
    display: flex; }
  .ini.dropdown .menu {
    background: #fff;
    border: 1px solid #f5f5f5;
    position: absolute;
    font-size: 14px;
    height: 0;
    opacity: 0;
    overflow: hidden;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: top;
    transform-origin: top;
    z-index: 2 !important; }
  .ini.dropdown .item {
    display: block;
    padding: 0.5rem 2rem; }
  .ini.dropdown .item:hover {
    background: #f5f5f5; }

.ini.dropdown.arrow .ini.button:after {
  content: "expand_more";
  font-family: "Material Icons";
  margin-left: 5px; }
.ini.dropdown.arrow .ini.button.active:after {
  content: "expand_less"; }

.ini.dropdown.active .menu {
  opacity: 1;
  height: auto;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  z-index: 1; }

.ini.dropdown.icon {
  position: relative; }
  .ini.dropdown.icon .menu {
    background: #fff;
    border: 1px solid #f5f5f5;
    position: absolute;
    font-size: 14px;
    height: 0;
    opacity: 0;
    overflow: hidden;
    min-width: 100%;
    width: -webkit-max-content;
    width: max-content;
    width: -moz-max-content;
    width: -o-max-content;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: top;
    transform-origin: top; }
  .ini.dropdown.icon .item {
    display: block;
    padding: 0.5rem 2rem; }
  .ini.dropdown.icon .item:hover {
    background: #f5f5f5; }
  .ini.dropdown.icon .material-icons {
    color: #fff;
    top: 8px;
    padding: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    pointer-events: none; }
  .ini.dropdown.icon .ini.button {
    padding-right: 0;
    padding-left: 0; }
  .ini.dropdown.icon .ini.button.blue .material-icons {
    background: #2980b9; }
  .ini.dropdown.icon .ini.button.o-blue .material-icons {
    color: #2980b9; }
  .ini.dropdown.icon .ini.button.inicss .material-icons {
    background: #fc732b; }
  .ini.dropdown.icon .ini.button.o-inicss .material-icons {
    color: #fc732b; }
  .ini.dropdown.icon .ini.button.red .material-icons {
    background: #f82d51; }
  .ini.dropdown.icon .ini.button.o-red .material-icons {
    color: #f82d51; }
  .ini.dropdown.icon .ini.button.gray .material-icons {
    background: #7f8c8d; }
  .ini.dropdown.icon .ini.button.o-gray .material-icons {
    color: #7f8c8d; }
  .ini.dropdown.icon .ini.button.black .material-icons {
    background: #000; }
  .ini.dropdown.icon .ini.button.o-black .material-icons {
    color: #000; }
  .ini.dropdown.icon .ini.button.orange .material-icons {
    background: #f39c12; }
  .ini.dropdown.icon .ini.button.o-orange .material-icons {
    color: #f39c12; }
  .ini.dropdown.icon .ini.button.carrot .material-icons {
    background: #e67e22; }
  .ini.dropdown.icon .ini.button.o-carrot .material-icons {
    color: #e67e22; }
  .ini.dropdown.icon .ini.button.purple .material-icons {
    background: #9b59b6; }
  .ini.dropdown.icon .ini.button.o-purple .material-icons {
    color: #9b59b6; }
  .ini.dropdown.icon .ini.button.midnight .material-icons {
    background: #2c3e50; }
  .ini.dropdown.icon .ini.button.o-midnight .material-icons {
    color: #2c3e50; }
  .ini.dropdown.icon .ini.button.green .material-icons {
    background: #2ecc71; }
  .ini.dropdown.icon .ini.button.o-green .material-icons {
    color: #2ecc71; }
  .ini.dropdown.icon .ini.button.o-white .material-icons {
    color: white; }
  .ini.dropdown.icon .text {
    margin-right: 15px;
    margin-left: 15px;
    pointer-events: none; }

.ini.dropdown.icon.active .menu {
  opacity: 1;
  height: auto;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transform-origin: top;
  transform-origin: top; }

/*
===============================================================
    INI TABS
===============================================================
*/
.ini.tabs.horizontal .tab.header ul {
  list-style: none;
  padding: 0;
  display: flex;
  margin: 0; }
  .ini.tabs.horizontal .tab.header ul li {
    flex: 1 1;
    padding: 0.8rem 0.7rem;
    cursor: pointer;
    text-align: center;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    border: 1px solid #ececec;
    border-right: none; }
  .ini.tabs.horizontal .tab.header ul li:last-child {
    border-right: 1px solid #ececec; }
  .ini.tabs.horizontal .tab.header ul li.active,
  .ini.tabs.horizontal .tab.header ul li:hover {
    background: whitesmoke;
    -webkit-transition: all 0.3s;
    transition: all 0.3s; }
  .ini.tabs.horizontal .tab.header ul li.ini.step {
    display: flex;
    align-items: center;
    position: relative;
    background: white;
    border: 1px solid #ececec;
    border-right: none;
    padding: 1.3rem 1rem; }
    .ini.tabs.horizontal .tab.header ul li.ini.step i {
      font-size: 38px; }
    .ini.tabs.horizontal .tab.header ul li.ini.step div {
      display: inline-block;
      margin-left: 0.5rem; }
  .ini.tabs.horizontal .tab.header ul li.ini.step:last-child {
    border-right: 1px solid #ececec; }
  .ini.tabs.horizontal .tab.header ul li.ini.step::after {
    content: "";
    position: absolute;
    right: -0.55rem;
    height: 1rem;
    width: 1rem;
    background: white;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    z-index: 1;
    border: 1px solid #ececec;
    border-width: 1px 1px 0px 0px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s; }
  .ini.tabs.horizontal .tab.header ul li.ini.step:last-child::after {
    content: none; }
  .ini.tabs.horizontal .tab.header ul li.ini.step.active,
  .ini.tabs.horizontal .tab.header ul li.ini.step:hover {
    background: whitesmoke;
    border-radius: 0; }
  .ini.tabs.horizontal .tab.header ul li.ini.step.active::after,
  .ini.tabs.horizontal .tab.header ul li.ini.step:hover::after {
    background: whitesmoke;
    -webkit-transition: all 0.3s;
    transition: all 0.3s; }
.ini.tabs.horizontal .tab.body {
  padding: 0.5rem;
  margin-top: 0.5rem; }
  .ini.tabs.horizontal .tab.body .content {
    display: none;
    min-height: 10rem; }
  .ini.tabs.horizontal .tab.body .content.active {
    display: block; }

.ini.tabs.vertical {
  display: flex; }
  .ini.tabs.vertical .tab.header {
    border: 1px solid #ececec;
    flex: 1 1;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    margin-right: 1rem; }
    .ini.tabs.vertical .tab.header ul {
      list-style: none;
      padding: 0;
      margin: 0; }
      .ini.tabs.vertical .tab.header ul li {
        border-bottom: 1px solid #ececec;
        padding: 1rem 0.7rem;
        cursor: pointer;
        text-align: center;
        min-width: 8rem; }
      .ini.tabs.vertical .tab.header ul li:last-child {
        border-bottom: none; }
      .ini.tabs.vertical .tab.header ul li.active,
      .ini.tabs.vertical .tab.header ul li:hover {
        background: whitesmoke; }
  .ini.tabs.vertical .tab.body {
    flex: 1 1;
    padding: 0.5rem; }
    .ini.tabs.vertical .tab.body .content {
      display: none; }
    .ini.tabs.vertical .tab.body .content.active {
      display: block; }

/*
===============================================================
    INI PROGRESS
===============================================================
*/
.ini.progress {
  width: 100%;
  height: 1.5rem;
  background: #f5f5f5;
  border-radius: 5px;
  overflow: hidden; }
  .ini.progress .bar {
    max-width: 100%;
    height: 100%;
    background: #2980b9;
    color: #fff;
    text-align: center;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    position: relative;
    display: grid;
    align-content: center; }
  .ini.progress .bar.black {
    background: #000; }
  .ini.progress .bar.red {
    background: #f82d51; }
  .ini.progress .bar.green {
    background: #2ecc71; }
  .ini.progress .bar.purple {
    background: #9b59b6; }
  .ini.progress .bar.carrot {
    background: #e67e22; }
  .ini.progress .bar.orange {
    background: #f39c12; }
  .ini.progress .bar.gray {
    background: #7f8c8d; }
  .ini.progress .bar.midnight {
    background: #2c3e50; }
  .ini.progress .striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
    background-size: 2rem 2rem !important; }
  .ini.progress .bar.animated:after {
    content: "";
    opacity: 0;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: #ffffff;
    border-radius: 0.28571429rem;
    -webkit-animation: bar-animated 2.5s ease infinite;
    animation: bar-animated 2.5s ease infinite; }
  .ini.progress .striped-animated {
    -webkit-animation: bar-striped-animated 1.5s linear infinite;
            animation: bar-striped-animated 1.5s linear infinite; }

.ini.progress.simple {
  height: 0.2rem;
  font-size: 0px; }

.ini.progress.small {
  height: 1rem;
  font-size: 12px; }

.ini.progress.large {
  height: 2rem; }

.ini.progress.big {
  height: 3rem; }

@-webkit-keyframes bar-animated {
  0% {
    opacity: 0.3;
    width: 0; }
  100% {
    opacity: 0;
    width: 100%; } }
@keyframes bar-animated {
  0% {
    opacity: 0.3;
    width: 0; }
  100% {
    opacity: 0;
    width: 100%; } }
@-webkit-keyframes bar-striped-animated {
  from {
    background-position: 0 0; }
  to {
    background-position: 2rem 0; } }
@keyframes bar-striped-animated {
  from {
    background-position: 0 0; }
  to {
    background-position: 2rem 0; } }
/*
===============================================================
    INI LOADER
===============================================================
*/
.ini.loader {
  display: block;
  height: 32px;
  width: 32px;
  position: relative; }

.ini.loader.inverse {
  color: #fff; }

.ini.loader:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 32px;
  width: 32px;
  border: 3px solid transparent;
  border-top: 3px solid #000;
  border-radius: 50%;
  -webkit-animation: outer 0.9s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  animation: outer 0.9s cubic-bezier(0.77, 0, 0.175, 1) infinite; }

.ini.inverse.loader:after {
  border-top: 3px solid #fff !important; }

@-webkit-keyframes outer {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }
@keyframes outer {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }
.ini.loader:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 32px;
  width: 32px;
  border: 3px solid #000;
  opacity: 0.2;
  border-radius: 50%; }

.ini.inverse.loader:before {
  border: 3px solid #fff;
  opacity: 0.2; }

.ini.overlay {
  background: rgba(255, 255, 255, 0.85);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex; }
  .ini.overlay .loader {
    margin: auto; }

.ini.overlay.inverse {
  background: rgba(0, 0, 0, 0.85); }

.ini.loader.text {
  width: auto;
  height: auto;
  padding-top: 3.5rem; }

/*
===============================================================
    INI LIST
===============================================================
*/
.ini.list.vertical ul {
  list-style: none;
  padding: 0;
  margin: 0; }
  .ini.list.vertical ul li a {
    display: block;
    padding: 0.7rem 0 0.7rem 0.4rem;
    -webkit-transition: all 0.3s;
    transition: all 0.3s; }
  .ini.list.vertical ul li a:hover {
    background: #ececec;
    -webkit-transition: all 0.3s;
    transition: all 0.3s; }

/*
===============================================================
    INI MESSAGE
===============================================================
*/
.ini.message {
  border-radius: 0.3rem;
  padding: 1rem;
  margin-bottom: 1rem; }
  .ini.message .header {
    font-weight: 700;
    margin-bottom: 0.3rem; }
  .ini.message .close {
    float: right;
    font-size: 18px;
    position: relative;
    top: 15px;
    font-weight: bold;
    cursor: pointer; }

.ini.message.basic {
  box-shadow: 0 0 0 1px rgba(156, 156, 156, 0.24);
  background: #fafafa; }

.ini.message.info {
  box-shadow: 0 0 0 1px rgba(5, 66, 94, 0.24);
  background: #e0e7ff; }
  .ini.message.info .header,
  .ini.message.info .body,
  .ini.message.info i {
    color: #1f748c; }

.ini.message.success {
  box-shadow: 0 0 0 1px rgba(19, 164, 8, 0.24);
  background: #d9ffdf; }
  .ini.message.success .header,
  .ini.message.success .body,
  .ini.message.success i {
    color: #0e7b43; }

.ini.message.danger {
  box-shadow: 0 0 0 1px rgba(224, 24, 24, 0.24);
  background: #ffe9e9; }
  .ini.message.danger .header,
  .ini.message.danger .body,
  .ini.message.danger i {
    color: #b53f3f; }

.ini.message.warning {
  box-shadow: 0 0 0 1px rgba(224, 179, 24, 0.24);
  background: #fffdcc; }
  .ini.message.warning .header,
  .ini.message.warning .body,
  .ini.message.warning i {
    color: #b38534; }

.ini.message.compact {
  padding: 0; }
  .ini.message.compact .header,
  .ini.message.compact .body {
    padding: 0.5rem 1rem;
    margin-bottom: 0; }
  .ini.message.compact .closeMessage {
    top: 1.4rem;
    right: 1rem; }

.ini.message.compact.basic .header {
  background: #efefef; }

.ini.message.compact.info .header {
  background: #a0d5f3; }

.ini.message.compact.success .header {
  background: #9be6ab; }

.ini.message.compact.danger .header {
  background: #fbd1d1; }

.ini.message.compact.warning .header {
  background: #f9ebb9; }

/*# sourceMappingURL=ini.css.map */

body{
  min-height: 100vh;
  margin: 0;
  font-family: 'PT Sans';
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.container {
  margin: 0 auto;
  position: relative;
  width: auto;

}
@media (max-width: 1022px) {
  .container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
@media (min-width: 1023px) and (max-width: 1215px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1216px) and (max-width: 1440px) {
  .container {
    max-width: 1152px;
  }
}
@media (min-width: 1441px) {
  .container {
    max-width: 1344px;
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
img{
  max-width: 100%;
}
